<template>
    <div>
        <Pane />
        <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false">
            <div class="container">
                <div class="header">
                    <img src="../../../assets/contract-register/icon-info.png" alt="" />
                    <span>基本信息</span>
                </div>
                <a-row>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="申请部门">
                            <a-button block style="text-align: left">{{
                                                            detail.applyDeptName
                                                            }}</a-button>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="申请人">
                            <a-button block style="text-align: left">{{
                                                            detail.applyUserName
                                                            }}</a-button>
                        </a-form-item>
                    </a-col>

                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="合同名称">
                            <a-button class="ellipsis" style="text-align: left" block>
                                {{ detail.name }}
                            </a-button>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="设计编号">
                            <a-button block style="text-align: left">
                                {{ detail.code }}
                            </a-button>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="原合同金额(万元)">
                            <a-button block style="text-align: left">
                                <Money :money="detail.amountOld" />
                            </a-button>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="变更后合同金额(万元)">
                            <a-button block style="text-align: left">
                                <Money :money="detail.amountNew" />
                            </a-button>
                        </a-form-item>
                    </a-col>
                </a-row>
            </div>

            <div class="container">
                <div class="header">
                    <img src="../../../assets/contract-register/icon-project.png" alt="" />
                    <span>子项目列表</span>
                </div>

                <Padding size="large" />

                <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" label="子项目列表">
                    <div style="margin-top: 4px">
                        <a-table bordered :dataSource="Array.isArray(detail.projectList) ? detail.projectList : []
                                                    " :pagination="false">
                            <a-table-column title="设计编号" width="140px">
                                <template slot-scope="text">
                                    {{ text.designCode }}
                                </template>
                            </a-table-column>
                            <a-table-column title="子项目名称">
                                <template slot-scope="text">
                                    {{ text.name }}
                                </template>
                            </a-table-column>
                            <a-table-column title="原子项目金额(万元)" align="right" width="150px">
                                <template slot-scope="text">
                                    <Money :money="text.amountOld" />
                                </template>
                            </a-table-column>
                            <a-table-column title="变更后子项目金额(万元)" align="right" width="150px">
                                <template slot-scope="text">
                                    <Money :money="text.amountNew" />
                                </template>
                            </a-table-column>
                        </a-table>
                    </div>
                </a-form-item>

                <Padding size="large" />
            </div>

            <div class="container">
                <div class="header">
                    <img src="../../../assets/contract-register/icon-file.png" alt="" />
                    <span>内部资料</span>




                </div>

                <Padding size="large" />

                <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" label="内部资料">
                    <div style="margin-top: 4px">
                        <a-table bordered :dataSource="Array.isArray(detail.internalFileList) ? detail.internalFileList : []

                                                    " :pagination="false">
                            <a-table-column title="文件名">
                                <template slot-scope="text">
                                    <a :href="text.completePath" target="_blank">
                                        {{ text.name }}
                                    </a>
                                </template>
                            </a-table-column>

                        </a-table>
                    </div>
                </a-form-item>

                <Padding size="large" />
            </div>
            <div class="container">
                <div class="header">
                    <img src="../../../assets/contract-register/icon-file.png" alt="" />
                    <span>外部资料</span>




                </div>

                <Padding size="large" />

                <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" label="外部资料">
                    <div style="margin-top: 4px">
                        <a-table bordered :dataSource="Array.isArray(detail.externalFileList) ? detail.externalFileList : []
                                                    " :pagination="false">
                            <a-table-column title="文件名">
                                <template slot-scope="text">
                                    <a :href="text.completePath" target="_blank">
                                        {{ text.name }}
                                    </a>
                                </template>
                            </a-table-column>


                        </a-table>
                    </div>
                </a-form-item>

                <Padding size="large" />
            </div>


            <div class="center">
                <a-space>
                    <a-button @click="$close($route.path)">关闭</a-button>
                </a-space>
            </div>
        </a-form>

    </div>
</template>
  
<script>
import request from '@/api/request'

function fetchDetail(data) {
    return request({
        url: '/project-service/contract/change/detail/' + data.id,
        method: 'post',
    })
}


export default {

    data() {
        return {
            detail: {},
        };
    },

    mounted() {
        const { id } = this.$route.query;
        fetchDetail({
            id,
        }).then(res => {
            this.detail = res ?? {}
        })
    },
};
</script>
  
<style lang="less" scoped>
.container {
    background-color: #fff;

    .header {
        display: flex;
        align-items: center;
        padding: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid #f0f0f0;
        color: #1890ff;
        font-weight: bold;
        font-size: 14px;

        img {
            width: 14px;
            margin-right: 4px;
        }

        .extra {
            margin-left: auto;
        }
    }

    .tip {
        color: #999;
    }
}

.center {
    margin-top: 80px;
    margin-bottom: 80px;
}
</style>
  